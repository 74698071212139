export const getPluralForm = number => {
  const lastDigit = number % 10;
  if (number === 1 || (number > 20 && lastDigit === 1)) {
    return 'приз остался';
  } else if (lastDigit >= 2 && lastDigit <= 4 && (number > 20 || number < 5)) {
    return 'приза осталось';
  } else {
    return 'призов осталось';
  }
};
