import { modalAnimations } from './modal';
import { board } from './game/init';
import { SoundBoard } from './sounds';
import gsap from 'gsap/all';
import { hideLoader } from './game/loader';

export const ZeroState = {
  init: () => {
    const isFirstVisit = storage.check();

    if (isFirstVisit) {
      hideLoader();

      //// Background blur
      const blur = document.createElement('div');
      blur.classList.add('darkBackground');

      //// Modal
      const modalWrapper = document.createElement('div');
      modalWrapper.classList.add('modalWrapper');
      modalWrapper.classList.add('zeroState');

      // Header
      const gifstImage = document.createElement('img');
      gifstImage.src = new URL('../../static/images/gifts.png', import.meta.url);
      gifstImage.classList.add('zeroStateThemeImg');

      const header = document.createElement('div');
      header.classList.add('zeroStateHeader');
      const h1 = document.createElement('h1');
      h1.textContent = 'swipe a gift and';
      const h2 = document.createElement('h2');
      h2.textContent = 'Make 3 in a row!';

      header.append(h1, h2);

      // Main Images
      const mainImages = document.createElement('div');
      mainImages.classList.add('mainZSImages');

      const zeroStateImage = document.createElement('img');
      zeroStateImage.src = new URL('../../static/images/zeroState.png', import.meta.url);
      zeroStateImage.classList.add('zeroStateImage');

      const hand = document.createElement('img');
      hand.src = new URL('../icons/hand.png', import.meta.url);
      hand.classList.add('hand');

      mainImages.append(zeroStateImage, hand);

      // Footer
      const playButton = document.createElement('button');
      playButton.textContent = 'Let’s play!';
      playButton.classList.add('primary');

      //// Filling DOM
      // modalWrapper.append(gifstImage, header, mainImages, playButton);
      modalWrapper.append(header, mainImages, playButton);

      document.body.append(blur, modalWrapper);

      gsap.from([blur, modalWrapper], {
        autoAlpha: 0,
        duration: 0.5,
        delay: 2, //duration of css animation
      });

      gsap.to(hand, {
        repeat: -1,
        duration: 2,
        keyframes: {
          '0%': {
            autoAlpha: 0,
            scale: 1,
            y: '0%',
          },
          '25%': {
            autoAlpha: 1,
            scale: 0.8,
            y: '0%',
          },
          '75%': {
            autoAlpha: 1,
            scale: 0.8,
            y: '-100%',
          },
          '100%': {
            autoAlpha: 0,
            scale: 1,
            y: '-90%',
          },
        },
      });

      // Yandex
      // if (window.ym) {
      //   ym(window.yandexId, 'reachGoal', 'webEvent_gameInfo_view', {
      //     customer_id: localStorage.getItem('customerId'),
      //   });
      // }

      // gtag('event', 'webEvent_gameInfo_view', {
      //   'customer_id': localStorage.getItem('customerId'),
      // });
      // sendData('webEvent_gameInfo_view', {
      //   'customer_id': localStorage.getItem('customerId'),
      // })

      // Events
      blur.addEventListener('click', () => {
        ZeroState.close(blur, modalWrapper);

        // Yandex
        // if (window.ym) {
        //   ym(window.yandexId, 'reachGoal', 'webEvent_gameInfo_play_click', {
        //     customer_id: localStorage.getItem('customerId'),
        //   });
        // }
        // gtag('event', 'webEvent_gameInfo_play_click', {
        //   'customer_id': localStorage.getItem('customerId'),
        // });
        // sendData('webEvent_gameInfo_play_click', {
        //   'customer_id': localStorage.getItem('customerId'),
        // })
      });
      playButton.addEventListener('click', () => {
        sendData('webEvent_game_second_page_lets_play');
        ZeroState.close(blur, modalWrapper);

        // Yandex
        // if (window.ym) {
        //   ym(window.yandexId, 'reachGoal', 'webEvent_gameInfo_play_click', {
        //     customer_id: localStorage.getItem('customerId'),
        //   });
        // }

        // gtag('event', 'webEvent_gameInfo_play_click', {
        //   'customer_id': localStorage.getItem('customerId'),
        // });
        // sendData('webEvent_gameInfo_play_click', {
        //   'customer_id': localStorage.getItem('customerId'),
        // })
        sendData('webEvent_game_init');
      });
    }
  },
  close: () => {
    modalAnimations.fade('out', '.darkBackground');
    modalAnimations.out('.modalWrapper', () => {
      document.querySelectorAll('.darkBackground, .modalWrapper').forEach(el => el.remove());
      storage.change('false');
      board.start();
    });
    SoundBoard.play('moveTwo');
  },
};

export const storage = {
  check: () => {
    if (!localStorage.getItem('firstVisit')) {
      storage.change();
      return true;
    } else if (localStorage.getItem('firstVisit') === 'true') {
      return true;
    } else {
      return false;
    }
  },
  change: (state = 'true') => {
    localStorage.setItem('firstVisit', state);
  },
};
