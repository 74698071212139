import gsap from 'gsap';
import { getRandomPosition } from './utils';
import { SoundBoard } from './sounds';
import { Application, Container, Sprite } from 'pixi.js';
import blue from '../assets/images/gift/Blue.png';
import pink from '../assets/images/gift/Pink.png';
import burst1 from '../assets/images/gift/Starburst-1.png';
import burst2 from '../assets/images/gift/Starburst-2.png';
import burst3 from '../assets/images/gift/Starburst-3.png';
import dot from '../assets/images/gift/Dot.png';
import star from '../assets/images/gift/Star.png';
import { PixiPlugin } from 'gsap/PixiPlugin';

gsap.registerPlugin(PixiPlugin);

export const Gift = {
  app: new Application({
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundAlpha: 0,
    antialias: true,
    resolution: window.devicePixelRatio,
    resizeTo: window,
  }),
  dots: Array.from(Array(10)),
  stars: Array.from(Array(10)),
  burstsContainer: new Container(),
  container: new Container(),
  bursts: [],
  gift: new Sprite.from(document.documentElement.classList.contains('pink') ? pink : blue),
  init() {
    document.body.insertAdjacentHTML(
      'beforeend',
      `<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" style="position: absolute;top: 0;left: 0;pointer-events: none;z-index: -1;opacity: 0;max-width: 100%;"><filter id="blur"><feGaussianBlur stdDeviation="3" /></filter></svg>`,
    );

    this.app.view.classList.add('gift-canvas');
    document.body.appendChild(this.app.view);

    this.app.loader
      .add('blue', new URL('../assets/images/gift/Blue.png', import.meta.url).href)
      .add('pink', new URL('../assets/images/gift/Pink.png', import.meta.url).href)
      .add('dot', new URL('../assets/images/gift/Dot.png', import.meta.url).href)
      .add('star', new URL('../assets/images/gift/Star.png', import.meta.url).href)
      .add('burst1', new URL('../assets/images/gift/Starburst-1.png', import.meta.url).href)
      .add('burst2', new URL('../assets/images/gift/Starburst-2.png', import.meta.url).href)
      .add('burst3', new URL('../assets/images/gift/Starburst-3.png', import.meta.url).href);

    this.app.loader.onComplete.add(() => {
      const dotsContainer = new Container();
      this.dots = this.dots.map(() => {
        const sprite = Sprite.from(dot);
        dotsContainer.addChild(sprite);
        return sprite;
      });

      const starsContainer = new Container();
      this.stars = this.stars.map(() => {
        const sprite = Sprite.from(star);
        starsContainer.addChild(sprite);
        return sprite;
      });

      this.bursts.push(Sprite.from(burst1), Sprite.from(burst2), Sprite.from(burst3));

      this.bursts.forEach(burst => {
        burst.anchor.set(0.5);
        this.burstsContainer.addChild(burst);
      });
      this.burstsContainer.x = window.innerWidth / 2;
      this.burstsContainer.y = window.innerHeight / 2;

      this.gift.anchor.set(0.5);
      this.gift.x = window.innerWidth / 2;
      this.gift.y = window.innerHeight / 2;

      this.container.addChild(dotsContainer, starsContainer, this.burstsContainer, this.gift);

      this.container.interactive = true;
      this.container.on('pointertap', () => {
        // Yandex
        // if (window.ym) {
        //   ym(window.yandexId, 'reachGoal', 'webEvent_game_celebration_tap', {
        //     customer_id: localStorage.getItem('customerId'),
        //   });
        // }
        // sendData('webEvent_game_celebration_tap', {'customer_id': localStorage.getItem('customerId')})
        // gtag('event', 'webEvent_game_celebration_tap', {'customer_id': localStorage.getItem('customerId')});
        this.close();
      });

      this.app.stage.addChild(this.container);
    });
    this.app.loader.load();
  },
  open(callback) {
    SoundBoard.play('gift');
    const positions = getRandomPosition(20, 40);
    const tl = gsap.timeline();

    this.dots.forEach((dot, i) => {
      dot.position.x = positions[i].left;
      dot.position.y = positions[i].top;
    });

    this.stars.forEach((star, i) => {
      star.position.x = positions[i + this.dots.length].left;
      star.position.y = positions[i + this.dots.length].top;
    });

    this.app.view.classList.add('active');

    tl.set('.wrapper', {
      filter: 'url(#blur)',
    })
      .fromTo(
        [this.bursts, this.container, this.gift],
        {
          alpha: 0,
        },
        { alpha: 1, duration: 1 },
        0,
      )
      .to(
        this.bursts,
        {
          rotation: x => (x % 2 === 0 ? -360 : 360),
          repeat: -1,
          duration: () => gsap.utils.random(5000, 6500),
          ease: 'linear',
        },
        0,
      )
      .fromTo(
        this.burstsContainer.scale,
        {
          x: 1.8,
          y: 1.8,
        },
        {
          x: 2.2,
          y: 2.2,
          repeat: -1,
          yoyo: true,
          duration: () => gsap.utils.random(1, 2.5),
          delay: () => gsap.utils.random(0, 4),
        },
        1,
      )
      .fromTo(
        this.gift.scale,
        {
          x: 0,
          y: 0,
        },
        {
          x: 1,
          y: 1,
          duration: 1,
          ease: 'elastic.out',
        },
        1,
      )
      .to(
        this.gift,
        {
          y: window.innerHeight / 2 - 16,
          repeat: -1,
          yoyo: true,
          duration: 1,
          ease: 'elastic.out',
        },
        1,
      );

    // Yandex
    // if (window.ym) {
    //   ym(window.yandexId, 'reachGoal', 'webEvent_game_celebration_view', {
    //     customer_id: localStorage.getItem('customerId'),
    //   });
    // }
    // gtag('event', 'webEvent_game_celebration_view', {'customer_id': localStorage.getItem('customerId')});
    // sendData('webEvent_game_celebration_view', {'customer_id': localStorage.getItem('customerId')})

    setTimeout(() => {
      this.close(callback);
    }, 2750);
  },
  close(callback) {
    const tl = gsap.timeline({
      onComplete: () => {
        callback && callback();
        this.app.view.classList.remove('active');
      },
    });

    tl.set('.wrapper', {
      filter: 'none',
    })
      .to(
        this.gift,
        {
          alpha: 0,
        },
        0,
      )
      .to(
        this.gift.scale,
        {
          x: 2.5,
          y: 2.5,
        },
        0,
      )
      .to(
        this.container,
        {
          alpha: 0,
        },
        0,
      );
  },
  resize() {
    this.burstsContainer.x = window.innerWidth / 2;
    this.burstsContainer.y = window.innerHeight / 2;

    this.gift.x = window.innerWidth / 2;
    this.gift.y = window.innerHeight / 2;
  },
};

window.onresize = Gift.resize.bind(Gift);
Gift.init();
