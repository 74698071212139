export const SoundBoard = {
  sounds: {
    match: new URL('../assets/sounds/match.wav', import.meta.url),
    gift: new URL('../assets/sounds/gift.wav', import.meta.url),
    move: new URL('../assets/sounds/move.wav', import.meta.url),
    moveTwo: new URL('../assets/sounds/moveTwo.wav', import.meta.url),
    countdown: new URL('../assets/sounds/countdown.wav', import.meta.url),
  },
  play: sound => {
    const audioEl = document.getElementById(sound);
    audioEl.src = SoundBoard.sounds[sound];
    audioEl.play();
  },
};

// Magic for IOS as described here: https://stackoverflow.com/a/54119854/4291750
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
