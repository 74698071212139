import { app } from './scripts/game/init';
import { stats } from './scripts/debug';
import { ZeroState } from './scripts/zeroState';
import { sponsorsInit } from './scripts/sponsors';
// Keep for init
import { notification } from './scripts/notification';
import { HighScore } from './scripts/highScore';

let initialTouched = false;

app.ticker.add(() => {
  stats?.begin();

  stats?.end();
});

const message = {
  bejeweled: {
    action: 'game-loaded',
  },
};
window.parent.postMessage(message, '*');

sendData('webEvent_game_second_page_init');

ZeroState.init();
HighScore.init();

if (!location.search.includes('debug=true')) {
  window.addEventListener('message', event => {
    if (!event.data?.bejeweled?.sponsors) return;
    sponsorsInit(event.data.bejeweled.sponsors);
  });
} else {
  window.addEventListener('game-play', event => {
    if (!event.detail.sponsors) return;
    sponsorsInit(event.detail.sponsors);
  });
}

window.addEventListener('touchstart', () => {
  if (!initialTouched) {
    initialTouched = true;
    document.querySelectorAll('audio').forEach(el => el.play());
  }
});

const headerCloseButton = document.querySelector('header .closeButton');
headerCloseButton.addEventListener('click', () => {
  const message = {
    bejeweled: {
      action: 'close',
    },
  };
  window.parent.postMessage(message, '*');

  sendData('webEvent_game_close');
});
