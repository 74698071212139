import { utils } from 'pixi.js';
import { comments } from '../comments';

export class Timer extends utils.EventEmitter {
  #animationClass = 'animate-bar';
  #animationBackgroundClass = 'animate-bar-background';
  #timerElementSelector = 'data-timer-element';
  #timerElement = document.querySelector(`[${this.#timerElementSelector}]`);

  constructor() {
    super();
    if (!this.#timerElement) {
      console.error('No element to showcase score on');
      return;
    }

    this.#timerElement.addEventListener('animationend', state => {
      comments.setMsg('timeRunOut');
      this.emit('timerEnded');
      this.#timerElement.style.animationPlayState = state ? 'paused' : 'running';
      this.#timerElement.classList.remove(this.#animationBackgroundClass);
    });
  }

  start() {
    this.#timerElement.classList.add(this.#animationClass, this.#animationBackgroundClass);
  }

  resetTimer() {
    this.#timerElement.classList.remove(this.#animationClass, this.#animationBackgroundClass);
    // Triggers reflow
    // From: https://css-tricks.com/restart-css-animation/#update-another-javascript-method-to-restart-a-css-animation
    void this.#timerElement.offsetWidth;

    this.#timerElement.classList.add(this.#animationClass, this.#animationBackgroundClass);
  }

  set pause(state) {
    this.#timerElement.style.animationPlayState = state ? 'paused' : 'running';
  }

  isPaused() {
    return this.#timerElement.style.animationPlayState === 'paused';
  }
}
