import { Sprite, utils } from 'pixi.js';
import { isDebug } from '../debug.js';
import cup from '../../assets/images/game-pieces/cup.png';
import milk from '../../assets/images/game-pieces/milk.png';
import bread from '../../assets/images/game-pieces/bread.png';
import soup from '../../assets/images/game-pieces/soup.png';
import egg from '../../assets/images/game-pieces/egg.png';
import ground from '../../assets/images/game-pieces/ground.png';
import gsap from 'gsap/all';
import { numberOfColumns } from './init';

const types = {
  cup,
  soup,
  egg,
  milk,
  bread,
  ground,
};

export class Piece extends utils.EventEmitter {
  isInDraggingState = false;
  positionMarginX = 0.13;
  positionMarginY = 0.13;
  startingPosition;
  enableMovement = true;

  constructor(x, y, size, type) {
    super();
    this.x = x;
    this.y = y;
    this.size = size;
    this.type = type;
    this.thunder = false;

    this.element = Sprite.from(types[type]);
    this.element.width *= (this.size * 0.75) / this.element.width;
    this.element.height *= (this.size * 0.75) / this.element.height;

    this.setStartPosition();
    this.updatePositionBasedOnXAndY();
    this.element.anchor.set(0.5);

    this.element.interactive = true;
    this.element.buttonMode = true;

    this.element
      .on('pointerdown', () => this.onDragStart())
      .on('pointerup', () => this.onDragEnd())
      .on('pointerout', () => this.onDragEnd())
      .on('pointerleave', () => this.onDragEnd())
      .on('pointerupoutside', () => this.onDragEnd())
      .on('pointermove', e => this.onDragMove(e))
      .on('pointertap', () => {
        this.emit('hintClick');
      });
  }

  onDragStart() {
    if (!this.enableMovement) return;
    this.isInDraggingState = true;
    this.startingPosition = {
      x: this.element.position.x,
      y: this.element.position.y,
    };
    this.element.zIndex = 1;
  }

  onDragMove(e) {
    if (!this.isInDraggingState) return;
    const movementPadding = 10; // Yes yes, magic numbers but otherwise it would have cost me 2 hours instead of 1 minute
    const newPosition = e.data.getLocalPosition(this.element.parent);
    if (
      Math.abs(this.startingPosition.x - newPosition.x) >
      Math.abs(this.startingPosition.y - newPosition.y)
    ) {
      // Min Max is to set boundries to one tile in both directions
      // Min(x, size) takes the smalles number, making it not go past the boundrie
      // Max(x, -size) takes the biggest number, making it not go past the boundrie
      this.element.position.x =
        this.startingPosition.x -
        Math.max(
          Math.min(
            this.startingPosition.x -
              (newPosition.x - this.size * this.positionMarginX) +
              movementPadding,
            this.size,
          ),
          -this.size,
        );
      this.element.position.y = this.startingPosition.y;
    } else {
      this.element.position.x = this.startingPosition.x;
      this.element.position.y =
        this.startingPosition.y -
        Math.max(
          Math.min(
            this.startingPosition.y -
              (newPosition.y - this.size * this.positionMarginY) +
              movementPadding,
            this.size,
          ),
          -this.size,
        );
    }
  }

  onDragEnd() {
    if (!this.isInDraggingState) return;
    this.isInDraggingState = false;
    this.element.zIndex = 0;

    // Percentage wise most of it is over another block
    if (
      Math.round(Math.abs((this.startingPosition.x - this.element.position.x) / this.size)) ||
      Math.round(Math.abs((this.startingPosition.y - this.element.position.y) / this.size))
    ) {
      // Minus before is to turn -1 into 1 and 1 into -1
      // This is because for going from left to right the value would otherwise be -1 (going backwards)
      const x = -Math.round((this.startingPosition.x - this.element.position.x) / this.size);
      const y = -Math.round((this.startingPosition.y - this.element.position.y) / this.size);
      this.emit('over', { x, y });
    } else {
      this.moveBackToPreviousPosition();
    }
  }

  moveBackToPreviousPosition() {
    this.element.x = this.startingPosition.x;
    this.element.y = this.startingPosition.y;
  }

  setStartPosition() {
    this.element.position.x =
      this.x * this.size + this.size * this.positionMarginX + this.element.width * 0.5;
    this.element.position.y -= (numberOfColumns - this.y) * this.size + this.element.height * 0.5;
  }

  async updatePositionBasedOnXAndY() {
    const to =  {
      x: this.x * this.size + this.size * this.positionMarginX + this.element.width * 0.5,
      y: this.y * this.size + this.size * this.positionMarginY + this.element.height * 0.5,
    };
    const from = this.element.position;

    if(this.thunder && (to.x !== from.x || to.y !== from.y)) this.emit('on-thunder-update-position', { from, to });

    return gsap.to(from, to);
  }

  // This method destroys the pixi.js graphic. Otherwise it stays in memory.
  async destroy() {
    return new Promise(resolve => {
      gsap.to(this.element, {
        alpha: 0,
        onComplete: () => {
          if (this.element) {
            this.element.destroy();
          }
          resolve();
        },
      });
    });
  }

  removePiece() {
    this.emit('removePiece');
  }
}
