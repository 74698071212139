import { Graphics, Container, Sprite, Text } from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';
import { randomIntFromInterval } from '../utils';
import star from '../../assets/images/gift/Star.png';

import gsap from 'gsap';

export class SelectThunderEffect {
  constructor(piece, tileSize) {
    this.piece = piece;
    this.tileSize = tileSize;
    this.particleAmount = 8;

    this.container = new Container();

    const baseElement = new Graphics();
    baseElement.lineStyle(2, 0xffffff, 1);

    baseElement.beginFill(0xffffff, 0);

    const { x, y } = this.piece;


    const starParticle = Sprite.from(star);

    const starParticleScale = 2;
    starParticle.x = x * this.tileSize + this.tileSize/2;
    starParticle.y = y * this.tileSize + this.tileSize/2;
    starParticle.scale.set(starParticleScale);
    starParticle.rotation = randomIntFromInterval(0, 360);

    starParticle.anchor.set(0.5);

    baseElement.addChild(starParticle);

    this.container.addChild(baseElement);

    this.container.filters = [new GlowFilter({ distance: 10, outerStrength: 2, innerStrength: 6 })];
    this.container.alpha = 0;

    const timeline = gsap.timeline();
    timeline.fromTo(
      this.container.children[0].children,
      {
        alpha: 0,
      },
      {
        alpha: 0.1,
        duration: 0.5,
        stagger: 0.05,
      },
      0.1,
    );

    timeline.to(
      this.container,
      {
        alpha: 1,
        duration: 1,
      },
      0,
    );
  }

  removeMatchEffect() {
    if (!this.container) return;
    gsap.to(this.container, {
      alpha: 0,
      onComplete: () => {
        this.container.children.forEach(childContainer => {
          childContainer.children.forEach(child => {
            child.destroy();
          });
        });
      },
    });
  }
}
