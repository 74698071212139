import { Container, Graphics, Sprite, Texture } from "pixi.js";
import * as PIXI from "pixi.js";
import { app, numberOfRows, numberOfColumns, boardMargin } from "./init";
import { Piece } from "./piece";
import {
  possibilitiesFives,
  possibilitiesFours,
  possibilitiesThrees,
  possibilitiesTwos,
  possibilitiesTriangle,
  possibilitiesJay,
} from "./matchPossibilities";
import { deepClone, moveInArray, wait } from "../utils";
import { ScoreKeeper } from "./scoreKeeper";
import { Timer } from "./timer.js";
import { Gift } from "../gift";
import gsap from "gsap";
import { Hint } from "./hint.js";
import { MatchEffect } from "./matchEffect";
import { SelectThunderEffect } from "./SelectThunderEffect";
import { SoundBoard } from "../sounds";
import { comments } from "../comments";
import { getPluralForm } from "../pluralCounting";
import { HighScore } from "../highScore";
import { sponsorsInit } from "../sponsors";
import cloneDeep from "lodash.clonedeep";
import ground from "../../assets/images/game-pieces/ground.png";

class Board {
  hint;
  boardColors = [0.4, 0.1];
  _lineRow = 3;
  _groundColumnSize = 3;
  lineThickness = 1.5;
  lineColor = 0xffffff;
  rowNum = 5.3;

  // piecesTypes = ['red', 'yellow', 'blue', 'green', 'pink'];
  piecesTypes = ['cup', 'soup', 'egg', 'milk', 'bread',"grounds"];

  giftsCounter = document.querySelector("[data-num-of-gifts]");
  selectThunderEffect = [];

  constructor(size, numberOfGifts, autostart) {
    this.tileSize = size;
    this.numberOfGifts = numberOfGifts;
    this.tilesContainer = new Container();
    this.piecesContainer = new Container();

    this.tiles = [];
    this.pieces = [];

    this.score = new ScoreKeeper();
    this.timer = new Timer();

    if (numberOfGifts === 0) {
      HighScore.show();
    } else {
      this.setRemainingGifts();
    }

    this.timer.on("timerEnded", () => {
      this.score.toCountDownScore = true;

      this.timerHintCountdown = setTimeout(() => {
        this.showHint();
      }, 4000);
    });

    for (let x = 0; x < numberOfRows; x++) {
      this.tiles.push([]);
      this.pieces.push([]);

      for (let y = 0; y < numberOfColumns; y++) {
        this.tiles[x].push(new Graphics());
        let type = this.calculateStartingType(x, y);
        const countOfGroundColumn = numberOfColumns - this._groundColumnSize;

        if (y >= countOfGroundColumn) {
          type = "ground";
        }

        this.pieces[x].push(new Piece(x, y, this.tileSize, type));

        const ground = this.tiles[x][y];
        const piece = this.pieces[x][y];

        ground.beginFill(0x000000, this.boardColors[(x + y) % 2]);
        ground.drawRect(
          x * this.tileSize,
          y * this.tileSize,
          this.tileSize,
          this.tileSize,
        );

        this.tilesContainer.addChild(ground);
        this.piecesContainer.addChild(piece.element);

        piece.on("over", (data) => {
          this.pieceOverAnother(piece, data);
        });

        piece.on("on-thunder-update-position", ({ from }) => {
          this.thunderUpdatePosition(piece, from);
        });

        piece.on("removePiece", () => {
          this.removePiece(piece, this.pieces);
        });

        piece.on("hintClick", () => {
          if (!this.hint) return;
          if (
            piece.x === this.hint.position.hintPosition[0] &&
            piece.y === this.hint.position.hintPosition[1]
          ) {
            this.moveHintPiece(this.hint.position);
          }
        });
      }
      // Change this to the desired row number
      const lineY =
        this.rowNum * this.tileSize + this.tileSize / 2 + boardMargin / 2;

      const line = new Graphics();
      line.lineStyle(this.lineThickness, this.lineColor);
      line.x -= 9;
      line.у = 20;
      line.moveTo(boardMargin / 2, lineY);
      line.lineTo(numberOfColumns * this.tileSize + boardMargin / 2, lineY);
      this.piecesContainer.addChild(line);
    }

    this.tilesContainer.x = boardMargin / 2;
    this.tilesContainer.y = boardMargin / 2;
    this.piecesContainer.x = boardMargin / 2;
    this.piecesContainer.y = boardMargin / 2;

    app.stage.addChild(this.tilesContainer);
    app.stage.addChild(this.piecesContainer);

    // Makes it so that if you change the zIndex value the render order changes aswell
    this.piecesContainer.sortableChildren = true;

    if (this.numberOfGifts) {
      this.showHint();
    }

    if (autostart) {
      this.start();
    }

    this.initMessageListener();
  }

  start() {
    this.timer.start();
  }

  calculateStartingType(x, y) {
    const availableTypes = [...this.piecesTypes.slice(0, 3)];
    if (
      this.pieces?.[x - 1]?.[y]?.type === this.pieces?.[x - 2]?.[y]?.type &&
      availableTypes.indexOf(this.pieces?.[x - 1]?.[y]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x - 1]?.[y]?.type),
        1,
      );
    }

    // Check previous and next
    if (
      this.pieces?.[x - 1]?.[y]?.type &&
      this.pieces?.[x - 1]?.[y]?.type === this.pieces?.[x + 1]?.[y]?.type &&
      availableTypes.indexOf(this.pieces?.[x - 1]?.[y]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x - 1]?.[y]?.type),
        1,
      );
    }

    // With the next items check if they exits. When this is first run they will not exist yet
    // This will mean both will be undefied and thus match
    if (
      this.pieces?.[x + 1]?.[y]?.type &&
      this.pieces?.[x + 1]?.[y]?.type === this.pieces?.[x + 2]?.[y]?.type &&
      availableTypes.indexOf(this.pieces?.[x + 1]?.[y]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x + 1]?.[y]?.type),
        1,
      );
    }

    if (
      this.pieces?.[x]?.[y - 1]?.type === this.pieces?.[x]?.[y - 2]?.type &&
      availableTypes.indexOf(this.pieces?.[x]?.[y - 1]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x]?.[y - 1]?.type),
        1,
      );
    }

    // Check previous and next
    if (
      this.pieces?.[x]?.[y - 1]?.type &&
      this.pieces?.[x]?.[y - 1]?.type === this.pieces?.[x]?.[y + 1]?.type &&
      availableTypes.indexOf(this.pieces?.[x]?.[y - 1]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x]?.[y - 1]?.type),
        1,
      );
    }

    // With the next items check if they exits. When this is first run they will not exist yet
    // This will mean both will be undefied and thus match
    if (
      this.pieces?.[x]?.[y + 1]?.type &&
      this.pieces?.[x]?.[y + 1]?.type === this.pieces?.[x]?.[y + 2]?.type &&
      availableTypes.indexOf(this.pieces?.[x]?.[y + 1]?.type) !== -1
    ) {
      availableTypes.splice(
        availableTypes.indexOf(this.pieces?.[x]?.[y + 1]?.type),
        1,
      );
    }

    return availableTypes[Math.floor(Math.random() * availableTypes.length)];
  }

  pieceOverAnother(piece, data) {
    const newX = piece.x + data.x;
    const newY = piece.y + data.y;

    // Piece doesn't exist
    if (!this.pieces[newX] || !this.pieces[newX][newY]) {
      piece.moveBackToPreviousPosition();
      return;
    }
    const pieceToSwapWith = this.pieces[newX][newY];
    const piecesCopy = deepClone(this.pieces);
    piecesCopy[newX][newY] = piece;
    piecesCopy[piece.x][piece.y] = pieceToSwapWith;

    const matchesOne = this.calculateForMatch(piecesCopy, {
      x: newX,
      y: newY,
    });
    const matchesTwo = this.calculateForMatch(piecesCopy, {
      x: piece.x,
      y: piece.y,
    });

    SoundBoard.play("move");

    if (matchesOne.length || matchesTwo.length) {
      this.enablePieces(false);
    }

    if (matchesOne.length && matchesTwo.length) {
      this.handleMatch(
        [
          matchesOne.map((match) => [piece, ...match]),
          matchesTwo.map((match) => [pieceToSwapWith, ...match]),
        ].flat(),
        piecesCopy,
        [piece, pieceToSwapWith],
      );
    } else if (matchesOne.length) {
      this.handleMatch(
        [matchesOne.map((match) => [piece, ...match])].flat(),
        piecesCopy,
        [piece, pieceToSwapWith],
      );
    } else if (matchesTwo.length) {
      this.handleMatch(
        [matchesTwo.map((match) => [pieceToSwapWith, ...match])].flat(),
        piecesCopy,
        [piece, pieceToSwapWith],
      );
    } else {
      // Set moved pieces back to it's previous position
      piece.moveBackToPreviousPosition();
    }
  }

  calculateForMatch(pieces, pieceCordinates) {
    const { x, y } = pieceCordinates;
    const centerPiece = pieces[x][y];
    if (centerPiece.type === "ground") return [];

    const allMatches = [];

    const filterForDups = (matchToCheck, allMatches) => {
      return matchToCheck.filter((match) => {
        const matchAlreadyExists = allMatches.flat().find((previousMatch) => {
          return previousMatch.find((prevMatch) => {
            return match.find((m) => {
              return prevMatch[0] === m[0] && prevMatch[1] === m[1];
            });
          });
        });
        return !matchAlreadyExists;
      });
    };

    const fiveMatch = possibilitiesFives(x, y).filter((possibilities) =>
      possibilities.every(
        (cord) => pieces?.[cord[0]]?.[cord[1]]?.type === centerPiece.type,
      ),
    );

    if (fiveMatch.length) {
      allMatches.push(fiveMatch);
    }

    const fourMatch = possibilitiesFours(x, y).filter((possibilities) =>
      possibilities.every(
        (cord) => pieces?.[cord[0]]?.[cord[1]]?.type === centerPiece.type,
      ),
    );

    if (fourMatch.length) {
      const filteredFourMatches = filterForDups(fourMatch, allMatches);

      if (filteredFourMatches.length) {
        allMatches.push(filteredFourMatches);
      }
    }

    const threeMatch = possibilitiesThrees(x, y).filter((possibilities) =>
      possibilities.every(
        (cord) => pieces?.[cord[0]]?.[cord[1]]?.type === centerPiece.type,
      ),
    );

    if (threeMatch.length) {
      const filteredThreeMatches = filterForDups(threeMatch, allMatches);

      if (filteredThreeMatches.length) {
        allMatches.push(filteredThreeMatches);
      }
    }

    const mapCordToPiece = (cords) =>
      cords.map((cord) => pieces[cord[0]][cord[1]]);
    const allMatchesFlattened = allMatches.flat().map(mapCordToPiece);

    return allMatchesFlattened;
  }

  async handleMatch(matches, newPiecesArray, movedPieces, firstMoved = true) {
    this.timer.pause = true;
    this.score.toCountDownScore = false;
    this.removeHint();
    // If the timout for a hint is already set clear it
    // clearTimeout(this.timerHintCountdown);

    if (this.numberOfGifts === 0 && !HighScore.isShowing) {
      HighScore.show();
    }

    if (this.numberOfGifts === 0 && !comments.isOutOfGifts) {
      comments.outOfGifts();
    }

    if (movedPieces && movedPieces.length) {
      const posOne = { x: movedPieces[0].x, y: movedPieces[0].y };
      const posTwo = { x: movedPieces[1].x, y: movedPieces[1].y };

      // swap x and y
      movedPieces[0].x = posTwo.x;
      movedPieces[0].y = posTwo.y;
      movedPieces[1].x = posOne.x;
      movedPieces[1].y = posOne.y;

      await Promise.all([
        movedPieces[0].updatePositionBasedOnXAndY(),
        movedPieces[1].updatePositionBasedOnXAndY(),
      ]);
    }

    const removePiecesArrayPromise = [];

    let pieces = matches.map((row) => row.map((piece) => piece)).flat();

    const thunders = pieces.filter(({ thunder }) => thunder);
    if (thunders.length) {
      thunders.forEach(async (thunder) => {
        const removedPiece = this.getThunderPieces(this.pieces, thunder);
        pieces = pieces.concat(removedPiece.flat());
        matches = [...matches, ...removedPiece];
      })

      // pieces = [...new Set(pieces.flat().flat())];
    }

    const pieceElements = pieces.map((piece) => piece.element);

    this.showMatchEffect(matches);
    this.score.increaseScoreBy(matches);

    const tl = gsap.timeline({
      paused: true,
    });

    pieceElements.forEach((piece, i) => {
      if (!piece) return;
      const delay = i * 0.075;
      const duration = 0.125;

      tl.to(
        piece.scale,

        {
          duration: duration,
          x: 0.325,
          y: 0.325,
          delay: delay,
        },
        0,
      );
      tl.to(
        piece.scale,
        {
          duration: duration * 4,
          x: 0.625,
          y: 0.625,
        },
        duration + delay,
      );
      tl.to(
        piece,
        {
          duration: duration * 2,
          alpha: 0,
        },
        duration * 2 + delay,
      );
    });

    const playTl = await tl.play();
    playTl.then(async () => {
      new Set(pieces).forEach((piece) => {
        removePiecesArrayPromise.push(this.removePiece(piece, newPiecesArray));
      });

      this.removeMatchEffect();
    });
    await Promise.all(removePiecesArrayPromise);

    this.pieces = newPiecesArray;
    this.refillEmptyTiles();
    this.sendGift(firstMoved);
  }

  checkGroundAboveLine() {
    return this.pieces
      .map((column) => column[column.length - this._lineRow])
      .flat()
      .filter(({ type }) => type === "ground").length;
  }

  async checkForGroundAboveLine() {
    const isGroundAboveLine = this.checkGroundAboveLine();
    if (!isGroundAboveLine) {
      for (let x = 0; x < this.pieces.length; x++) {
        const pieces = this.pieces[x].splice(0, 2);
        this.removePieceBunch(pieces);

        for (let y = 0; y < 2; y++) {
          const piece = new Piece(
            x,
            this.pieces[x].length,
            this.tileSize,
            "ground",
          );
          this.pieces[x].push(piece);
          this.piecesContainer.addChild(piece.element);
        }
      }

      this.updateCoordXY();

      await this.updateAllPiecesPositionBasedOnXAndYCoordinates();
    }
  }

  removePieceBunch(pieces) {
    pieces.forEach((piece) => {
      if (!piece) {
        return;
      }
      this.piecesContainer.removeChild(piece.element);
      piece.destroy();
    });
  }

  updateCoordXY() {
    for (let x = 0; x < this.pieces.length; x++) {
      for (let y = 0; y < this.pieces[x].length; y++) {
        this.pieces[x][y].x = x;
        this.pieces[x][y].y = y;
      }
    }
  }

  sendGift(firstMoved) {
    sendData("webEvent_game_match", { score: this.score.getScore() });
    if (firstMoved && this.numberOfGifts !== 0 && this.score.getScore() > 400) {
      if (!location.search.includes("debug=true")) {
        const message = {
          bejeweled: {
            action: "game-won",
          },
        };
        window.parent.postMessage(message, "*");
      } else {
        window.dispatchEvent(new CustomEvent("game-won"));
      }
      this.numberOfGifts -= 1;

      this.setRemainingGifts();
      setTimeout(() => {
        console.log("matchEffect", this);
        this.timer.pause = true;
        this.timer.resetTimer();
        Gift.open();
      }, 250);
    }
  }

  getThunderPieces (pieces, thunder) {
    const piecesFromRow = [];
    const piecesFromColumn = [];

    for (let i = 0; i < pieces.length; i++) {
      piecesFromRow.push(pieces[i][thunder.y]);

      for (let j = 0; j < pieces[i].length; j++) {
        if (i === thunder.x) {
          piecesFromColumn.push(pieces[i][j]);
        }
      }
    }

    return [piecesFromRow, piecesFromColumn];
  }

  removeGround(piece, pieces) {
    const neighbors = this.getNeighbors(piece.x, piece.y, pieces);
    const groundsNeighbors = neighbors.filter(
      (neighbor) => neighbor && neighbor.type === "ground",
    );
    for (const groundsNeighbor of groundsNeighbors) {
      pieces[groundsNeighbor.x][groundsNeighbor.y] = null;

      this.piecesContainer.removeChild(groundsNeighbor.element);
      groundsNeighbor.destroy();
    }
  }

  async removePiece(piece, pieces) {
    this.removeSelectThunderEffect(piece);
    this.removeGround(piece, pieces);

    pieces[piece.x][piece.y] = null;

    this.piecesContainer.removeChild(piece.element);

    piece.destroy();
  }

  getNeighbors(x, y, pieces) {
    const neighbors = [];
    const directions = [
      { dx: -1, dy: 0 },
      { dx: 1, dy: 0 },
      { dx: 0, dy: -1 },
      { dx: 0, dy: 1 },
    ];

    for (const direction of directions) {
      const nx = x + direction.dx;
      const ny = y + direction.dy;

      if (nx >= 0 && ny >= 0 && nx < pieces.length && ny < pieces[0].length) {
        neighbors.push(pieces[nx][ny]);
      }
    }

    return neighbors;
  }

  async refillEmptyTiles() {
    this.removeGapsInTiles();
    this.setCorrectXAndYCoordinates();

    await this.updateAllPiecesPositionBasedOnXAndYCoordinates();
    let cantContinue = await this.checkAllPiecesForMatches();

    if (cantContinue) return;

    this.addNewPieces();

    const possibleMoves = this.checkForPossibleMoves();

    // If no more possible moves available remove random pieces until new moves are available
    if (!possibleMoves.length) {
      await this.randomlyRemovePieces(4);
      this.refillEmptyTiles();
      return;
    }

    this.enablePieces();

    if (!this.isFreezGame()) {
      this.timer.pause = false;
      this.timer.resetTimer();
    }
    if (this.numberOfGifts !== 0) {
      this.showHint();
    }

    this.checkForGroundAboveLine();
  }

  isFreezGame() {
    return this.freez;
  }

  freezGame(data) {
    this.freez = data;
    if (!data) {
      this.timer.pause = false;
      this.timer.resetTimer();
    } else {
      this.timer.pause = true;
    }
  }

  initMessageListener() {
    window.addEventListener("message", (event) => {
      if (typeof event.data?.bejeweled?.freez === "undefined") return;
      this.freezGame(event.data?.bejeweled?.freez);
    });
  }
  removeGapsInTiles() {
    for (let i = 0; i < this.pieces.length; i++) {
      const column = this.pieces[i];
      let count = 0;
      // // Calculate the gapes in a column, ignoring empties at the top
      for (let u = column.length - 1; 0 <= u; u--) {
        const piece = column[u];
        // If a gap increase counter
        if (!piece) {
          count++;
        } else {
          moveInArray(column, u, u + count);
        }
      }
    }
  }

  setCorrectXAndYCoordinates() {
    this.pieces.forEach((column, x) => {
      column.forEach((piece, y) => {
        if (!piece) return;
        piece.x = x;
        piece.y = y;
      });
    });
  }

  async updateAllPiecesPositionBasedOnXAndYCoordinates() {
    const piecesPromisesArray = [];
    this.pieces.forEach((column) => {
      column.forEach((piece) => {
        if (!piece) return;
        piecesPromisesArray.push(piece.updatePositionBasedOnXAndY());
      });
    });

    return Promise.all(piecesPromisesArray);
  }

  async checkAllPiecesForMatches() {
    let matches = [];
    this.pieces.forEach((column, x) => {
      column.forEach((piece, y) => {
        if (!piece) return;
        const match = this.calculateForMatch(this.pieces, {
          x,
          y,
        });
        if (match.length) {
          matches.push(match);
        }
      });
    });

    // Removes duplicates
    const filteredMatches = [...new Set(matches.flat().flat())];

    if (filteredMatches.length) {
      await this.mergePieces(filteredMatches, this.pieces);
      return true;
      // this.handleMatch([filteredMatches], deepClone(this.pieces), null, false);
      // return !!filteredMatches.length;
    }
    return false;
  }
  async mergePieces(pieces, newPiecesArray) {
    try {
      const base = pieces.pop();
      if(!base) return true;
      const dublPieces = cloneDeep(pieces);
      const removePiecesArrayPromise = [];

      pieces.forEach((piece) => {
        piece.x = base.x;
        piece.y = base.y;
      });
      const promise = pieces.map((piece) => piece.updatePositionBasedOnXAndY());

      await Promise.all(promise);

      const pieceElements = pieces.map((piece) => piece.element);

      const tl = gsap.timeline({
        paused: true,
      });

      pieceElements.forEach((piece, i) => {
        if (!piece) return;
        const delay = i * 0.075;
        const duration = 0.125;

        tl.to(
          piece.scale,
          {
            duration: duration,
            x: 0.325,
            y: 0.325,
            delay: delay,
          },
          0,
        );
        tl.to(
          piece.scale,
          {
            duration: duration * 4,
            x: 0.625,
            y: 0.625,
          },
          duration + delay,
        );
        tl.to(
          piece,
          {
            duration: duration * 2,
            alpha: 0,
          },
          duration * 2 + delay,
        );
      });

      const playTl = await tl.play();
      playTl.then(async () => {
        new Set(dublPieces).forEach((piece) => {
          removePiecesArrayPromise.push(
            this.removePiece(piece, newPiecesArray),
          );
        });

        await Promise.all(removePiecesArrayPromise);

        this.pieces = newPiecesArray;

        this.showSelectThunderEffect(base);
        this.refillEmptyTiles();
      });

      return true;
    } catch (e) {
      console.log(e);
    }
  }

  async thunderEffectPieces(pieces, newPiecesArray) {
    const pieceElements = pieces.map((piece) => piece.element);

    this.showMatchEffect([pieces]);
    const removePiecesArrayPromise = pieceElements;
    const tl = gsap.timeline({
      paused: true,
    });

    pieceElements.forEach((piece, i) => {
      if (!piece) return;
      const delay = i * 0.075;
      const duration = 0.125;

      tl.to(
        piece.scale,
        {
          duration: duration,
          x: 0.325,
          y: 0.325,
          delay: delay,
        },
        0,
      );
      tl.to(
        piece.scale,
        {
          duration: duration * 4,
          x: 0.625,
          y: 0.625,
        },
        duration + delay,
      );
      tl.to(
        piece,
        {
          duration: duration * 2,
          alpha: 0,
        },
        duration * 2 + delay,
      );
    });

    const playTl = await tl.play();
    playTl.then(() => {
      // Remove matches
      // Set to remove duplicates when you have a T shape match
      new Set(pieceElements).forEach((piece) => {
        removePiecesArrayPromise.push(this.removePiece(piece, newPiecesArray));
      });
      this.removeMatchEffect();
    });
    await Promise.all(removePiecesArrayPromise);
  }
  checkForPossibleMoves() {
    const piecesPerType = this.piecesTypes.map((type) =>
      this.pieces.flat().filter((piece) => piece?.type === type),
    );
    const allPossibleMoves = piecesPerType
      .map((piecesByType) => {
        const movesPerType = piecesByType
          .map((piece) => {
            const connectedMoves = [];

            const possibleConnectedMoves = possibilitiesJay(
              piece.x,
              piece.y,
            ).find(
              (p) =>
                this.pieces?.[p[0][0]]?.[p[0][1]]?.type === piece.type &&
                this.pieces?.[p[1][0]]?.[p[1][1]]?.type === piece.type,
            );

            if (possibleConnectedMoves) {
              // Second piece in the array is the piece that needs to move, aka the hint
              connectedMoves.push({
                hintPosition: possibleConnectedMoves[1],
                hintEndPosition: possibleConnectedMoves[2],
              });
            }

            const connectedTriangles = possibilitiesTriangle(
              piece.x,
              piece.y,
            ).find(
              (p) =>
                this.pieces?.[p[0][0]]?.[p[0][1]]?.type === piece.type &&
                this.pieces?.[p[1][0]]?.[p[1][1]]?.type === piece.type,
            );

            if (connectedTriangles) {
              // Second piece in the array is the piece that needs to move, aka the hint
              connectedMoves.push({
                hintPosition: connectedTriangles[1],
                hintEndPosition: connectedTriangles[2],
              });
            }

            return connectedMoves;
          })
          .filter((possibleMoves) => possibleMoves.length)
          .flat();

        const withoutDuploicatesMovesPerType = [];
        movesPerType.forEach((possibleMove) => {
          const alreadyInArray = withoutDuploicatesMovesPerType.find(
            (piece) =>
              possibleMove.hintPosition[0] === piece.hintPosition[0] &&
              possibleMove.hintPosition[1] === piece.hintPosition[1],
          );
          if (!alreadyInArray) {
            withoutDuploicatesMovesPerType.push(possibleMove);
          }
        });
        return withoutDuploicatesMovesPerType;
      })
      .flat();

    return allPossibleMoves;
  }

  addNewPieces() {
    for (let x = 0; x < this.pieces.length; x++) {
      const column = this.pieces[x];

      for (let y = 0; y < column.length; y++) {
        if (this.pieces[x][y]) continue;

        let type = this.calculateStartingType(x, y);

        this.pieces[x][y] = new Piece(x, y, this.tileSize, type);

        const piece = this.pieces[x][y];

        this.piecesContainer.addChild(piece.element);

        piece.on("over", (data) => {
          this.pieceOverAnother(piece, data);
        });

        piece.on("on-thunder-update-position", ({ from }) => {
          this.thunderUpdatePosition(piece, from);
        });

        piece.on("removePiece", () => {
          this.removePiece(piece, this.pieces);
        });

        piece.on("hintClick", () => {
          if (!this.hint) return;
          if (
            piece.x === this.hint.position.hintPosition[0] &&
            piece.y === this.hint.position.hintPosition[1]
          ) {
            this.moveHintPiece(this.hint.position);
          }
        });
      }
    }
  }

  thunderUpdatePosition(piece, from) {
    const pieceFrom = {
      x:
        (from.x -
          (piece.size * piece.positionMarginX + piece.element.width * 0.5)) /
        piece.size,
      y:
        (from.y -
          (piece.size * piece.positionMarginY + piece.element.height * 0.5)) /
        piece.size,
    };

    this.removeSelectThunderEffect(pieceFrom);
    this.showSelectThunderEffect(piece);
  }

  enablePieces(enable = true) {
    this.pieces.forEach((column) => {
      column.forEach((piece) => {
        piece.enableMovement = enable;
      });
    });
  }

  setRemainingGifts() {
    // const message = `${this.numberOfGifts} ${getPluralForm(this.numberOfGifts)}`;
    // this.giftsCounter.innerText = message;
    this.giftsCounter.innerText = "MATCH 3 OBJECTS";
  }

  showHint() {
    if (this.hint) return; // There already is a hint
    const possibleMoves = this.checkForPossibleMoves();
    const position =
      possibleMoves[Math.floor(Math.random() * possibleMoves.length)];
    this.hint = new Hint(position, this.tileSize);

    this.piecesContainer.addChild(this.hint.container);
  }

  async removeHint() {
    if (this.hint) {
      await this.hint.removeHint();
      this.hint = null;
    }
  }

  moveHintPiece({ hintPosition, hintEndPosition }) {
    this.pieces[hintPosition[0]][hintPosition[1]].emit("over", {
      x: hintEndPosition[0] - hintPosition[0],
      y: hintEndPosition[1] - hintPosition[1],
    });
  }

  showMatchEffect(matches) {
    SoundBoard.play("match");

    const scores = matches.map((match) => this.score.getScoreToBeGiven(match));

    this.matchEffect = new MatchEffect(matches, this.tileSize, scores);
    this.piecesContainer.addChild(this.matchEffect.container);
  }

  showSelectThunderEffect(piece) {
    this.pieces[piece.x][piece.y].thunder = true;
    const id = `${piece.x}_${piece.y}`;
    this.selectThunderEffect[id] = new SelectThunderEffect(
      piece,
      this.tileSize,
    );
    this.piecesContainer.addChild(this.selectThunderEffect[id].container);
  }

  removeSelectThunderEffect(piece) {
    const id = `${piece.x}_${piece.y}`;

    if (!this.selectThunderEffect[id]) return ;

    this.piecesContainer.removeChild(this.selectThunderEffect[id].container);
    this.selectThunderEffect[id].removeMatchEffect();
    this.selectThunderEffect[id] = null;
  }

  removeMatchEffect() {
    if (this.matchEffect) {
      this.matchEffect.removeMatchEffect();
      this.matchEffect = null;
    }
  }

  async randomlyRemovePieces(numberOfPiecesToRemove) {
    const positions = [];
    for (let i = 0; i < numberOfPiecesToRemove; i++) {
      let x, y;
      do {
        x = Math.floor(Math.random() * numberOfRows);
        y = Math.floor(Math.random() * numberOfColumns);
      } while (
        positions.find((position) => position[0] === x && position[1] === y)
        );
      positions.push([x, y]);
    }

    return Promise.all(
      positions.map((position) =>
        this.removePiece(this.pieces[position[0]][position[1]], this.pieces),
      ),
    );
  }
}

export { Board };
