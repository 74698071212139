const bottomPanel = document.querySelector('.bottom-panel');
const bottomText = document.querySelector('.bottom-bar');
let highScore = document.querySelector('#highscoreScore');

export const HighScore = {
  isShowing: false,
  score: 0,
  init: () => {
    if (localStorage.getItem('highScore')) {
      HighScore.score = localStorage.getItem('highScore');
    }
  },
  show: () => {
    if (!HighScore.isShowing) {
      HighScore.isShowing = true;

      bottomPanel.classList.add('showHighScore');

      const highscoreTextSpan = document.createElement('span');
      highscoreTextSpan.id = 'highscoreText';
      highscoreTextSpan.textContent = 'record ';

      const highscoreScoreSpan = document.createElement('span');
      highscoreScoreSpan.id = 'highscoreScore';
      highscoreScoreSpan.textContent = `${HighScore.score}`;

      bottomText.innerHTML = '';
      bottomText.append(highscoreTextSpan, highscoreScoreSpan);
    }
  },
  setNewHighScore: score => {
    if (HighScore.isShowing) {
      HighScore.updateScore(score);
    }
    HighScore.score = score;
    localStorage.setItem('highScore', score);
  },
  updateScore: score => {
    if (!highScore) {
      highScore = document.querySelector('#highscoreScore');
    }
    if (highScore === null || highScore.textContent === score) return;
    highScore.textContent = `${score}`;
  },
};
