import { Graphics, Container, Sprite, Text } from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';
import { randomIntFromInterval } from '../utils';
import dot from '../../assets/images/gift/Dot.png';
import star from '../../assets/images/gift/Star.png';

import gsap from 'gsap';

export class MatchEffect {
  constructor(matches, tileSize, scores) {
    this.matches = matches;
    this.tileSize = tileSize;
    this.particleAmount = 8;

    this.container = new Container();

    this.matches.forEach((match, i) => {
      const baseElement = new Graphics();
      baseElement.lineStyle(2, 0xffffff, 1);

      baseElement.beginFill(0xffffff, 0);

      const coords = match
        .map(({ x, y }) => ({ x, y }))
        //sort by ascending
        .sort((a, b) => a.x - b.x || a.y - b.y);

      // get the difference between highest and lowest values
      const xDiff =
        coords[0].x !== coords[coords.length - 1].x
          ? Math.abs(coords[0].x - coords[coords.length - 1].x) + 1
          : 1;
      const yDiff =
        coords[0].y !== coords[coords.length - 1].y
          ? Math.abs(coords[0].y - coords[coords.length - 1].y) + 1
          : 1;

      const { x, y } = coords[0];

      baseElement.drawRect(
        x * this.tileSize,
        y * this.tileSize,
        xDiff * this.tileSize,
        yDiff * this.tileSize,
      );
      baseElement.endFill();

      const scoreText = new Text(scores[i], {
        fontFamily: 'Geometria Narrow',
        fontSize: 30,
        fill: 'white',
        align: 'left',
      });
      scoreText.anchor.set(0.5);
      scoreText.position.set(
        x * this.tileSize + (xDiff * this.tileSize) / 2,
        y * this.tileSize + (yDiff * this.tileSize) / 2,
      );
      this.container.addChild(scoreText);

      for (let index = 0; index < this.particleAmount; index++) {
        const positions = [
          randomIntFromInterval(x * this.tileSize, x * this.tileSize + xDiff * this.tileSize),
          randomIntFromInterval(y * this.tileSize, y * this.tileSize + yDiff * this.tileSize),
          randomIntFromInterval(x * this.tileSize, x * this.tileSize + xDiff * this.tileSize),
          randomIntFromInterval(y * this.tileSize, y * this.tileSize + yDiff * this.tileSize),
        ];
        const dotParticle = Sprite.from(dot);
        const starParticle = Sprite.from(star);

        const dotParticleScale = randomIntFromInterval(0.25, 1, 2);
        dotParticle.x = positions[0];
        dotParticle.y = positions[1];
        dotParticle.anchor.set(0.5);
        dotParticle.scale.set(dotParticleScale);

        dotParticle.rotation = randomIntFromInterval(0, 360);

        const starParticleScale = randomIntFromInterval(0.25, 0.85, 2);
        starParticle.x = positions[2];
        starParticle.y = positions[3];
        starParticle.scale.set(starParticleScale);
        starParticle.rotation = randomIntFromInterval(0, 360);

        starParticle.anchor.set(0.5);

        baseElement.addChild(dotParticle, starParticle);
      }

      this.container.addChild(baseElement);
    });

    this.container.filters = [new GlowFilter({ distance: 10, outerStrength: 2, innerStrength: 6 })];
    this.container.alpha = 0;

    const timeline = gsap.timeline();
    timeline.fromTo(
      this.container.children[0].children,
      {
        alpha: 0,
      },
      {
        alpha: 0.1,
        duration: 0.5,
        stagger: 0.05,
      },
      0.1,
    );

    timeline.to(
      this.container,
      {
        alpha: 1,
        duration: 1,
      },
      0,
    );
  }

  removeMatchEffect() {
    if (!this.container) return;
    gsap.to(this.container, {
      alpha: 0,
      onComplete: () => {
        this.container.children.forEach(childContainer => {
          childContainer.children.forEach(child => {
            child.destroy();
          });
        });
      },
    });
  }
}
