import gsap from 'gsap';

export const hideLoader = () => {
  const tl = gsap.timeline({ paused: true });
  tl.set('.spinner div', {
    animationIterationCount: 1,
  })
    .to('.spinner div', {
      delay: 1.5,
      scaleY: 0,
      autoAlpa: 0,
      duration: 0.5,
      stagger: 0.025,
      ease: 'power3.in',
    })
    .to('.wrapper', {
      autoAlpa: 1,
      opacity: 1,
      duration: 1,
    })
    .play()
    .then(() => document.querySelector('.spinner-container').remove());
};
