const possibilitiesFives = (x, y) => [
  [
    [x + 1, y],
    [x + 2, y],
    [x + 3, y],
    [x + 4, y],
  ],
  [
    [x - 1, y],
    [x - 2, y],
    [x - 3, y],
    [x - 4, y],
  ],
  [
    [x + 1, y],
    [x + 2, y],
    [x - 1, y],
    [x - 2, y],
  ],
  [
    [x + 1, y],
    [x - 1, y],
    [x - 2, y],
    [x - 3, y],
  ],
  [
    [x + 3, y],
    [x + 2, y],
    [x + 1, y],
    [x - 1, y],
  ],
  [
    [x + 3, y],
    [x + 2, y],
    [x + 1, y],
    [x - 1, y],
  ],
  [
    [x, y + 1],
    [x, y + 2],
    [x, y + 3],
    [x, y + 4],
  ],
  [
    [x, y - 1],
    [x, y - 2],
    [x, y - 3],
    [x, y - 4],
  ],
  [
    [x, y + 1],
    [x, y + 2],
    [x, y - 1],
    [x, y - 2],
  ],
  [
    [x, y + 1],
    [x, y - 1],
    [x, y - 2],
    [x, y - 3],
  ],
  [
    [x, y + 3],
    [x, y + 2],
    [x, y + 1],
    [x, y - 1],
  ],
  [
    [x, y + 3],
    [x, y + 2],
    [x, y + 1],
    [x, y - 1],
  ],
];

const possibilitiesFours = (x, y) => [
  [
    [x + 1, y],
    [x + 2, y],
    [x + 3, y],
  ],
  [
    [x - 1, y],
    [x - 2, y],
    [x - 3, y],
  ],
  [
    [x + 1, y],
    [x - 1, y],
    [x - 2, y],
  ],
  [
    [x + 2, y],
    [x + 1, y],
    [x - 1, y],
  ],
  [
    [x, y + 1],
    [x, y + 2],
    [x, y + 3],
  ],
  [
    [x, y - 1],
    [x, y - 2],
    [x, y - 3],
  ],
  [
    [x, y + 1],
    [x, y - 1],
    [x, y - 2],
  ],
  [
    [x, y + 2],
    [x, y + 1],
    [x, y - 1],
  ],
];

const possibilitiesThrees = (x, y) => [
  [
    [x + 1, y],
    [x + 2, y],
  ],
  [
    [x - 1, y],
    [x - 2, y],
  ],
  [
    [x + 1, y],
    [x - 1, y],
  ],

  [
    [x, y + 1],
    [x, y + 2],
  ],
  [
    [x, y - 1],
    [x, y - 2],
  ],
  [
    [x, y + 1],
    [x, y - 1],
  ],
];

const possibilitiesTwoos = (x, y) => [
  [x + 1, y],
  [x - 1, y],
  [x, y + 1],
  [x, y - 1],
];

// A shape like
// XXO
// UUX
const possibilitiesJay = (x, y) => [
  [
    [x + 1, y],
    [x + 2, y + 1],
    [x + 2, y], // solution
  ],
  [
    [x + 1, y],
    [x + 2, y - 1],
    [x + 2, y], // solution
  ],
  [
    [x + 1, y],
    [x + 3, y],
    [x + 2, y], // solution
  ],

  [
    [x - 1, y],
    [x - 2, y + 1],
    [x - 2, y], // solution
  ],
  [
    [x - 1, y],
    [x - 2, y - 1],
    [x - 2, y], // solution
  ],
  [
    [x - 1, y],
    [x - 3, y],
    [x - 2, y], // solution
  ],

  [
    [x, y + 1],
    [x + 1, y + 2],
    [x, y + 2], // solution
  ],
  [
    [x, y + 1],
    [x - 1, y + 2],
    [x, y + 2], // solution
  ],
  [
    [x, y + 1],
    [x, y + 3],
    [x, y + 2], // solution
  ],

  [
    [x, y - 1],
    [x + 1, y - 2],
    [x, y - 2], // solution
  ],
  [
    [x, y - 1],
    [x - 1, y - 2],
    [x, y - 2], // solution
  ],
  [
    [x, y - 1],
    [x, y - 3],
    [x, y - 2], // solution
  ],
];

// A shape like
// XOX
// UXU
const possibilitiesTriangle = (x, y) => [
  [
    [x + 2, y],
    [x + 1, y + 1],
    [x + 1, y], // solution
  ],
  [
    [x + 2, y],
    [x + 1, y - 1],
    [x + 1, y], // solution
  ],
  [
    [x - 2, y],
    [x - 1, y + 1],
    [x - 1, y], // solution
  ],
  [
    [x - 2, y],
    [x - 1, y - 1],
    [x - 1, y], // solution
  ],

  [
    [x, y + 2],
    [x + 1, y + 1],
    [x, y + 1], // solution
  ],
  [
    [x, y + 2],
    [x - 1, y + 1],
    [x, y + 1], // solution
  ],
  [
    [x, y - 2],
    [x + 1, y - 1],
    [x, y - 1], // solution
  ],
  [
    [x, y - 2],
    [x - 1, y - 1],
    [x, y - 1], // solution
  ],
];

export {
  possibilitiesFives,
  possibilitiesFours,
  possibilitiesThrees,
  possibilitiesTwoos,
  possibilitiesTriangle,
  possibilitiesJay,
};
