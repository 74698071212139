import gsap from 'gsap';

const Bonjour = new URL('../../static/images/logos/Bonjour.png', import.meta.url);
const Felix = new URL('../../static/images/logos/Felix.png', import.meta.url);
const Glade = new URL('../../static/images/logos/Glade.png', import.meta.url);
const Rossu = new URL('../../static/images/logos/Rossu.png', import.meta.url);

const Milka = new URL('../../static/images/logos/Milka.png', import.meta.url);
const Antonio = new URL('../../static/images/logos/Antonio.png', import.meta.url);
const Hochland = new URL('../../static/images/logos/Hochland.png', import.meta.url);
const Whiskas = new URL('../../static/images/logos/Whiskas.png', import.meta.url);

const Duck = new URL('../../static/images/logos/Duck.png', import.meta.url);
const Gourmet = new URL('../../static/images/logos/Gourmet.png', import.meta.url);
const Mexx = new URL('../../static/images/logos/Mexx.png', import.meta.url);
const Vladimirski = new URL('../../static/images/logos/Vladimirski.png', import.meta.url);

const Maheev = new URL('../../static/images/logos/Maheev.png', import.meta.url);
const MaxFactor = new URL('../../static/images/logos/MaxFactor.png', import.meta.url);
const Mexx1 = new URL('../../static/images/logos/Mexx1.png', import.meta.url);
const Pedigree = new URL('../../static/images/logos/Pedigree.png', import.meta.url);

const Benetton = new URL('../../static/images/logos/Benetton.png', import.meta.url);
const Bruno = new URL('../../static/images/logos/Bruno.png', import.meta.url);
const Kitkat = new URL('../../static/images/logos/KItkat.png', import.meta.url);
const Purina = new URL('../../static/images/logos/Purina.png', import.meta.url);

const weeks = {
  week1: [Bonjour, Felix, Glade, Rossu],
  week2: [Milka, Antonio, Hochland, Whiskas],
  week3: [Duck, Gourmet, Mexx, Vladimirski],
  week4: [Maheev, MaxFactor, Mexx1, Pedigree],
  week5: [Benetton, Bruno, Kitkat, Purina],
};
export const sponsorsInit = sponsorWeek => {
  const sponsorsToUse = weeks[sponsorWeek];
  const sponsors = document.querySelector('.sponsors-list');

  sponsorsToUse.forEach(sponsor => {
    const li = `<li class="sponsors-list-item">
        <img src="${sponsor}" alt="" />
      </li>`;
    sponsors.insertAdjacentHTML('beforeend', li);
  });

  const sponsorItems = Array.from(sponsors.querySelectorAll('li'));
  const numberOfItems = sponsorItems.length;

  sponsorItems.forEach(element => {
    sponsors.appendChild(element.cloneNode(true));
  });
  sponsorItems.forEach(element => {
    sponsors.appendChild(element.cloneNode(true));
  });
  sponsorItems.forEach(element => {
    sponsors.appendChild(element.cloneNode(true));
  });

  const nextFirstItem = sponsors.querySelector(`li:nth-of-type(${numberOfItems + 1})`);
  const box = nextFirstItem.getBoundingClientRect();

  gsap.to(sponsors, {
    duration: 10,
    x: -box.x,
    ease: 'none',
    repeat: -1,
  });
};
