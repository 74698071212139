import { Application } from 'pixi.js';
import { Board } from './board';
import { storage } from '../zeroState';
import { hideLoader } from './loader';

const gameContainer = document.querySelector('#game-container');
// For rerendering to clean up the old scene
gameContainer.innerHTML = '';

const boardMargin = 20;
let numberOfGifts;

const numberOfRows = 6;
const numberOfColumns = 8;

const tileSize = window.innerWidth / numberOfRows - boardMargin / numberOfRows;
const width = window.innerWidth;
const height = tileSize * numberOfColumns + boardMargin;

const app = new Application({
  width,
  height,
  backgroundAlpha: 0,
  antialias: true,
  resolution: window.devicePixelRatio,
});

gameContainer.appendChild(app.view);

app.loader
  .add('cup', new URL('../../assets/images/game-pieces/cup.png', import.meta.url).href)
  .add('milk', new URL('../../assets/images/game-pieces/milk.png', import.meta.url).href)
  .add('bread', new URL('../../assets/images/game-pieces/bread.png', import.meta.url).href)
  .add('soup', new URL('../../assets/images/game-pieces/soup.png', import.meta.url).href)
  .add('egg', new URL('../../assets/images/game-pieces/egg.png', import.meta.url).href);

let board, gameAssetsLoaded;
app.loader.onComplete.add(() => {
  gameAssetsLoaded = true;
  startGame();
});
app.loader.load();
if (!location.search.includes('debug=true')) {
  window.addEventListener('message', event => {
    if (!event.data?.bejeweled?.period) return;
    numberOfGifts = event.data.bejeweled.availableGames;
    document.documentElement.classList.remove('blue');
    document.documentElement.classList.add(
      event.data.bejeweled.period === 'male' ? 'blue' : 'pink',
    );

    localStorage.setItem('customerId', event.data.bejeweled.customerId);
    startGame();
  });
} else {
  window.addEventListener('game-play', event => {
    if (!event.detail.period) return;
    numberOfGifts = event.detail.availableGames;
    document.documentElement.classList.remove('blue');
    document.documentElement.classList.add(event.detail.period === 'male' ? 'blue' : 'pink');
    startGame();
  });
}

function startGame() {
  if (typeof numberOfGifts !== 'number' || !gameAssetsLoaded) return;

  const isFirstVisit = storage.check();
  if (!isFirstVisit) {
    hideLoader();
  }

  board = new Board(tileSize, numberOfGifts, !isFirstVisit);

  if (location.search.includes('debug=true')) {
    window.board = board;
  }
}

export { app, tileSize, numberOfRows, numberOfColumns, boardMargin, board };
