let stats;
const isDebug = location.search.includes('debug=true');
const ispink = location.search.includes('type=pink');

if (isDebug) {
  import('../stats.module').then(({ default: Stats }) => {
    const _stats = document.querySelector('.stats');
    if (_stats) return;
    stats = new Stats();
    stats.dom.classList.add('stats');
    document.body.appendChild(stats.dom);
  });

  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent('game-play', {
        detail: {
          availableGames: 3 + Math.floor(Math.random() * 5),
          period: Math.floor(Math.random()) ? 'male' : 'female',
          sponsors: ['week1', 'week2', 'week3', 'week4', 'week5'][Math.floor(Math.random() * 5)],
        },
      }),
    );
  }, 1000);

  const prizes = [
    {
      title: 'Coca Cola six pack 330ML',
      discount: '25% off',
      imageUrl: 'https://nl.coca-cola.be/content/dam/one/be/nl/lead/coca-cola-logo-lead-nl.jpg',
      type: 'COUPON',
    },
    {
      title: '7up six pack 330ML',
      discount: '50% off',
      imageUrl: 'https://1000logos.net/wp-content/uploads/2018/01/7Up-Logo.png',
      type: 'COUPON',
    },
  ];

  window.addEventListener('game-won', () => {
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent('prize', {
          detail: {
            prize: prizes[Math.floor(Math.random() * prizes.length)],
          },
        }),
      );
    }, 200 * Math.floor(Math.random() * 10));
  });
}

if (ispink) {
  document.documentElement.classList.remove('blue');
  document.documentElement.classList.add('pink');
}

export { stats, isDebug };
