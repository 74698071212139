import gsap, { Elastic } from 'gsap/all';
import { HighScore } from './highScore';

let prevMsg = '';
let msgIsAlreadyShowing = false;

export const comments = {
  isOutOfGifts: false,
  setMsg: (route = 'default') => {
    const commentSection = document.querySelector('#commentSection');
    const tl = gsap.timeline();

    if (route === 'default' && comments.isOutOfGifts && HighScore.isShowing) {
      route = 'defaultOutOfGifts';
    }

    if (!msgIsAlreadyShowing) {
      msgIsAlreadyShowing = route.includes('default') ? false : true;
      const randomComment = Math.floor(Math.random() * comments[route].length);

      tl.to(commentSection, {
        autoAlpha: 0,
        scale: 0,
        onComplete: () => {
          commentSection.textContent = comments[route][randomComment];
        },
      }).to(commentSection, {
        autoAlpha: 1,
        scale: 1,
        ease: Elastic.easeOut,
      });

      if (!route.includes('default')) {
        setTimeout(() => {
          msgIsAlreadyShowing = false;
          comments.setMsg('default');
          prevMsg = route;
        }, 6000);
      }
    }
  },
  outOfGifts: () => {
    comments.isOutOfGifts = true;
    comments.setMsg('default');
  },
  default: ['Keep on going'],
  defaultOutOfGifts: [`Keep on going`],
  compliment: ['AND WIN A PRIZE'],
  bigCompliment: ['IMPROVE YOUR SCORE'],
  timeRunOut: ['Improve your score', 'Check your time']
};
