import { Graphics, Container } from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';

import gsap from 'gsap';

export class Hint {
  constructor(position, tileSize) {
    this.position = position;
    this.tileSize = tileSize;

    this.container = new Container();
    this.topLeftContainer = new Container();
    this.topRightContainer = new Container();
    this.bottomLeftContainer = new Container();
    this.bottomRightContainer = new Container();

    this.baseElement = new Graphics();
    this.baseElement.beginFill(0xffffff);
    this.baseElement.drawRect(0, 0, this.tileSize * 0.02, this.tileSize * 0.15);
    this.baseElement.endFill();

    this.makeCorner(this.topLeftContainer, 0);
    this.makeCorner(this.topRightContainer, -(Math.PI / 2));
    this.makeCorner(this.bottomLeftContainer, Math.PI / 2);
    this.makeCorner(this.bottomRightContainer, Math.PI);

    const halveTile = this.tileSize / 2;
    this.container.x = this.position.hintPosition[0] * this.tileSize + halveTile;
    this.container.y = this.position.hintPosition[1] * this.tileSize + halveTile;

    this.container.addChild(
      this.topLeftContainer,
      this.topRightContainer,
      this.bottomLeftContainer,
      this.bottomRightContainer,
    );

    this.container.filters = [new GlowFilter({ distance: 10, outerStrength: 2, innerStrength: 6 })];
    this.container.alpha = 0;

    gsap.to(this.container, {
      alpha: 1,
      duration: 1,
    });
    gsap.to(this.container.scale, {
      x: 1.1,
      y: 1.1,
      yoyo: true,
      repeat: -1,
      duration: 1,
    });
  }

  makeCorner(container, rotation) {
    const horizontal = this.baseElement.clone();
    const vertical = this.baseElement.clone();
    vertical.rotation -= Math.PI / 2;
    vertical.y = this.tileSize * 0.02;

    container.addChild(horizontal, vertical);

    const halveTile = this.tileSize / 2;
    container.pivot.x = halveTile;
    container.pivot.y = halveTile;

    container.rotation = rotation;
  }

  async removeHint() {
    await gsap.to(this.container, {
      alpha: 0,
    });

    this.container.destroy();
    this.baseElement.destroy();
    return;
  }
}
