import { Modal } from './modal';
import { SoundBoard } from './sounds';

export const Notification = {
  open: coupon => {
    const randomId = `${Math.floor(Math.random() * 999)}`;

    const notificationWrapper = document.createElement('div');
    notificationWrapper.classList.add('notificationWrapper');

    if (coupon.type === 'POSTCARD') {
      notificationWrapper.classList.add('postCard');
    }

    // Yandex
    window.couponType = coupon.type;

    notificationWrapper.id = randomId;

    const logoWrapper = document.createElement('div');
    logoWrapper.classList.add('logoWrapper');
    const logo = document.createElement('img');
    logo.src = coupon.imageUrl;
    logo.alt = `${coupon.title} logo`;
    logoWrapper.append(logo);

    const textContent = document.createElement('div');
    const youWonAGiftHeading = document.createElement('p');
    youWonAGiftHeading.innerText = 'Вы получили приз!';
    const openItToSee = document.createElement('p');
    openItToSee.innerText = 'Нажмите, чтобы увидеть его, или играйте дальше';
    textContent.append(youWonAGiftHeading, openItToSee);
    textContent.classList.add('textContent');

    const close = document.createElement('button');
    const closeIcon = document.createElement('img');
    closeIcon.src = new URL('../icons/cross.svg', import.meta.url);
    close.append(closeIcon);
    close.addEventListener('click', el => {
      el.stopPropagation();
      Notification.close(randomId);

      // Yandex
      // if (window.ym) {
      //   ym(window.yandexId, 'reachGoal', 'webEvent_game_giftWon_close_click', {
      //     customer_id: localStorage.getItem('customerId'),
      //     gift_type: window.couponType,
      //   });
      // }
      // gtag('event', 'webEvent_game_giftWon_close_click', {
      //   'customer_id': localStorage.getItem('customerId'),
      //    gift_type: window.couponType
      // });
      // sendData('webEvent_game_giftWon_close_click', {
      //   'customer_id': localStorage.getItem('customerId'),
      //   gift_type: window.couponType
      // })
    });
    notificationWrapper.append(logoWrapper, textContent, close);
    document.documentElement.append(notificationWrapper);

    requestAnimationFrame(() => {
      notificationWrapper.classList.add('open');
    });

    notificationWrapper.addEventListener('click', () => {
      SoundBoard.play('move');
      Modal.open(coupon);
      Notification.close(randomId);

      // Yandex
      // if (window.ym) {
      //   ym(window.yandexId, 'reachGoal', 'webEvent_game_giftWon_click', {
      //     customer_id: localStorage.getItem('customerId'),
      //     gift_type: window.couponType,
      //   });
      // }
      // gtag('event', 'webEvent_game_giftWon_click', {
      //   'customer_id': localStorage.getItem('customerId'),
      //   gift_type: window.couponType
      // });
      // sendData('webEvent_game_giftWon_click', {
      //   'customer_id': localStorage.getItem('customerId'),
      //   gift_type: window.couponType
      // })
    });

    setTimeout(() => {
      Notification.close(randomId);
    }, 5000);
  },
  close: id => {
    const toCloseNotification = document.getElementById(id);
    if (toCloseNotification?.classList?.contains('open')) {
      toCloseNotification.classList.remove('open');

      setTimeout(() => {
        toCloseNotification.remove();
      }, 500);
    }
  },
};

// Is triggered after a 'game-won' event
if (!location.search.includes('debug=true')) {
  window.addEventListener('message', event => {
    const prizeDetail = event.data?.bejeweled?.prize;
    if (prizeDetail) {
      Notification.open(prizeDetail);
    }
  });
} else {
  window.addEventListener('prize', event => {
    const prizeDetail = event.detail.prize;
    if (prizeDetail) {
      Notification.open(prizeDetail);
    }
  });
}
