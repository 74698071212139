import { comments } from '../comments';
import { HighScore } from '../highScore';
import { SoundBoard } from '../sounds';
import { app } from './init';

export class ScoreKeeper {
  toCountDownScore = false;
  // Have a local score because we use decimals internally but don't want to show this in the game
  #_score = 0;
  #score = 0;

  #smallRowPoints = 100;
  #mediumRowPoints = 200;
  #largeRowPoints = 350;

  #currentMultiplier = 0.8;

  #stageMultiplier = 0.5;
  #mediumDifficulty = {
    cap: 4000,
    multiplier: 0.75,
  };
  #hardDifficulty = {
    cap: 10000,
    multiplier: 0.9,
  };

  scoreBoardClass = 'score-board';
  scoreBoard = document.querySelector(`.${this.scoreBoardClass}`);

  constructor() {
    if (!this.scoreBoard) {
      console.error('No element to showcase score on');
      return;
    }

    this.setScore();

    app.ticker.add(delta => {
      if (!this.toCountDownScore) return;
      // Use delta here to keep the decrease consistent across framerates
      this.decreaseScoreBy(delta / 2);
      const playSoundOn =
        this.#_score < this.#mediumDifficulty.cap
          ? 75
          : this.#_score < this.#hardDifficulty.cap
          ? 150
          : 250;
      if (this.#score % playSoundOn === 0 && this.#score !== 0) {
        SoundBoard.play('countdown');
      }
    });
  }

  getScore() {
    return this.#_score;
  }

  setTimeDecreaseSpeed() {
    let sec = 7;

    if (this.#_score < this.#mediumDifficulty.cap) {
      this.#stageMultiplier = 0.5;
    }

    if (this.#_score >= this.#mediumDifficulty.cap) {
      this.#stageMultiplier = this.#mediumDifficulty.multiplier;
      sec = 5;
    }

    if (this.#_score >= this.#hardDifficulty.cap) {
      this.#stageMultiplier = this.#hardDifficulty.multiplier;
      sec = 3;
    }

    const currentScoreMultiplier = Math.floor(
      Math.floor(this.#_score / 1000) * this.#stageMultiplier + 1,
    );

    const animateBarBackground = document.querySelector('.game-timer-bar');

    if (animateBarBackground) {
      animateBarBackground.style.animationDuration = `${sec}s`;
    }

    this.#currentMultiplier = currentScoreMultiplier;
  }

  setScore() {
    if (!this.scoreBoard) return;
    this.scoreBoard.textContent = this.#score;
  }

  setCompliment(matches) {
    matches.forEach(match => {
      if (match.length === 4 || matches.flat().length === 6) {
        comments.setMsg('compliment');
      } else if (match.length === 5 || matches.flat().length >= 7) {
        comments.setMsg('bigCompliment');
      }
    });
  }

  increaseScoreBy(matches) {
    matches.forEach(match => {
      if (match.length === 3) {
        this.#_score += this.#smallRowPoints;
      } else if (match.length === 4) {
        this.#_score += this.#mediumRowPoints;
      } else {
        this.#_score += this.#largeRowPoints;
      }
    });

    this.#score = Math.round(this.#_score);
    this.setCompliment(matches);
    this.setScore();
    this.setTimeDecreaseSpeed();
    this.checkHighScore(this.#score);

    if (this.#score > 99999) {
      this.scoreBoard.style.fontSize = '6vw';
    } else {
      this.scoreBoard.style.fontSize = '8vw';
    }
    if (this.#score > 99999999) {
      this.scoreBoard.style.fontSize = '5vw';
    }
    if (this.#score > 999999999) {
      this.scoreBoard.style.fontSize = '4vw';
    }
  }

  checkHighScore(score) {
    if (score > HighScore.score) {
      HighScore.setNewHighScore(score);
    }
  }

  // This will later be needed when the timer reaches 0
  decreaseScoreBy(num) {
    this.#_score = Math.max(this.#_score - num * this.#currentMultiplier, 0);
    // Prevent unnecessary work when score is already 0
    if (this.#score !== Math.round(this.#_score)) {
      this.#score = Math.round(this.#_score);
      this.setScore();
    }
  }

  getScoreToBeGiven(matches) {
    if (matches.length === 3) {
      return this.#smallRowPoints;
    } else if (matches.length === 4) {
      return this.#mediumRowPoints;
    } else {
      return this.#largeRowPoints;
    }
  }
}
