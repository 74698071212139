import gsap, { Bounce, Power3 } from 'gsap/all';
import { board } from './game/init';
import { getPluralForm } from './pluralCounting';
import { SoundBoard } from './sounds';

export const Modal = {
  open: coupon => {
    board.timer.pause = true;
    //// Background blur
    const blur = document.createElement('div');
    blur.classList.add('darkBackground');

    //// Modal
    const modalWrapper = document.createElement('div');
    modalWrapper.classList.add('modalWrapper');

    if (coupon.type === 'POSTCARD') {
      modalWrapper.classList.add('postCard');
    }

    // Yandex
    window.couponType = coupon.type;

    // Top
    const discountLabel = document.createElement('p');
    discountLabel.classList.add('discountLabel');
    discountLabel.innerText = `${coupon.discount}`;

    const closeButton = document.createElement('button');
    const closeIcon = document.createElement('img');
    closeIcon.src = document.documentElement.classList.contains('blue')
      ? new URL('../icons/blueCross.svg', import.meta.url)
      : new URL('../icons/pinkCross.svg', import.meta.url);

    closeButton.classList.add('closeButton');

    closeButton.append(closeIcon);

    // Main
    const mainContent = document.createElement('div');
    mainContent.classList.add('mainContent');

    const logo = document.createElement('img');
    logo.src = coupon.imageUrl;
    logo.alt = `${coupon.title} logo`;

    const heading = document.createElement('h2');
    heading.textContent = coupon.title;
    const viewCouponButton = document.createElement('button');
    viewCouponButton.textContent = 'Подробнее';
    viewCouponButton.classList.add('primary', 'view-coupon');
    viewCouponButton.addEventListener('click', triggerPrizeDetails);

    // Footer
    const footer = document.createElement('footer');
    footer.classList.add(
      document.documentElement.classList.contains('blue') ? 'blueGradient' : 'pinkGradient',
    );

    const giftsRemaining = document.createElement('p');
    giftsRemaining.textContent = getPluralForm(board.numberOfGifts);
    // giftsRemaining.textContent = 'AND WIN A PRIZE';
    const amountOfGifts = document.createElement('span');
    amountOfGifts.textContent = board.numberOfGifts;

    const keepPlayingButton = document.createElement('button');
    keepPlayingButton.textContent = 'Продолжить игру';
    keepPlayingButton.classList.add('secondary');

    //// Filling DOM
    // Main
    const mainContentFragment = new DocumentFragment();
    mainContentFragment.append(logo, heading, viewCouponButton);
    mainContent.append(mainContentFragment);

    // Footer
    giftsRemaining.append(amountOfGifts);
    const footerFragment = new DocumentFragment();
    footerFragment.append(giftsRemaining, keepPlayingButton);
    footer.append(footerFragment);

    // Modal
    const modalWrapperFragment = new DocumentFragment();
    modalWrapperFragment.append(discountLabel, closeButton, mainContent, footer);
    modalWrapper.append(modalWrapperFragment);

    const bodyFragment = new DocumentFragment();
    bodyFragment.append(blur, modalWrapper);
    document.body.append(bodyFragment);

    modalAnimations.in(modalWrapper);
    modalAnimations.fade('in', blur);

    // Yandex
    // if (window.ym) {
    //   ym(window.yandexId, 'reachGoal', 'webEvent_winGift_view', {
    //     customer_id: localStorage.getItem('customerId'),
    //     gift_type: window.couponType,
    //   });
    // }
    // gtag('event', 'webEvent_winGift_view', {
    //   'customer_id': localStorage.getItem('customerId'),
    //    gift_type: window.couponType,
    // });
    // sendData('webEvent_winGift_view', {
    //   'customer_id': localStorage.getItem('customerId'),
    //    gift_type: window.couponType,
    // })

    document.querySelectorAll('.darkBackground, .modalWrapper button').forEach(node => {
      node.addEventListener('click', () => {
        SoundBoard.play('moveTwo');
        Modal.close();
      });
    });
  },
  removeModal: () => {
    document.querySelectorAll('.darkBackground, .modalWrapper').forEach(node => node.remove());
  },
  close: () => {
    board.timer.pause = false;
    modalAnimations.out('.modalWrapper', Modal.removeModal);
    modalAnimations.fade('out', '.darkBackground');
    document.querySelector('button.view-coupon').removeEventListener('click', triggerPrizeDetails);

    // Yandex
    // if (window.ym) {
    //   ym(window.yandexId, 'reachGoal', 'webEvent_winGift_keepPlaying_click', {
    //     customer_id: localStorage.getItem('customerId'),
    //     gift_type: window.couponType,
    //   });
    // }
    // gtag('event', 'webEvent_winGift_keepPlaying_click', {
    //   'customer_id': localStorage.getItem('customerId'),
    //    gift_type: window.couponType,
    // });
    // sendData('webEvent_winGift_keepPlaying_click', {
    //   'customer_id': localStorage.getItem('customerId'),
    //   gift_type: window.couponType,
    // })
  },
};

export const modalAnimations = {
  fade: (direction = 'in', node) => {
    const tl = gsap.timeline();
    tl.fromTo(
      node,
      {
        autoAlpha: direction === 'in' ? 0 : 0.6,
      },
      {
        autoAlpha: direction === 'in' ? 0.6 : 0,
      },
    );
  },
  in: node => {
    const tl = gsap.timeline();
    tl.fromTo(
      node,
      {
        scale: 0,
      },
      { autoAlpha: 1, scale: 1, ease: Bounce.easeOut, duration: 1 },
    );
  },
  out: (node, callback) => {
    gsap.to(node, {
      duration: 0.5,
      autoAlpha: 0,
      scale: 0,
      ease: Power3.easeOut,
      onComplete: () => {
        callback && callback();
      },
    });
  },
};

function triggerPrizeDetails() {
  const message = {
    bejeweled: {
      action: 'prize-detail',
    },
  };
  window.parent.postMessage(message, '*');

  // Yandex
  // if (window.ym) {
  //   ym(window.yandexId, 'reachGoal', 'webEvent_winGift_viewGift_click', {
  //     customer_id: localStorage.getItem('customerId'),
  //     gift_type: window.couponType,
  //   });
  // }
  // gtag('event', 'webEvent_winGift_viewGift_click', {
  //   'customer_id': localStorage.getItem('customerId'),
  //   gift_type: window.couponType,
  // });
  // sendData('webEvent_winGift_viewGift_click', {
  //   'customer_id': localStorage.getItem('customerId'),
  //   gift_type: window.couponType,
  // })
}
