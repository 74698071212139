export const deepClone = items => items.map(item => (Array.isArray(item) ? deepClone(item) : item));

export const wait = (timer = 1000) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timer);
  });

export const moveInArray = function (arr, from, to) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
};

export const getRandomPosition = (items, _size) => {
  const selected = [];
  const size = _size || 40;

  for (let i = 0; i < items; i++) {
    while (true) {
      const left = Math.floor(Math.random() * (window.innerWidth - size));
      const top = Math.floor(Math.random() * (window.innerHeight - size));
      const leftAndWidth = left + size;
      const topAndHeight = top + size;
      let i = 0;
      while (
        i < selected.length &&
        (left >= selected[i].leftAndWidth ||
          top >= selected[i].topAndHeight ||
          leftAndWidth <= selected[i].left ||
          topAndHeight <= selected[i].top)
      ) {
        i++;
      }
      if (i === selected.length) {
        selected.push({
          left: left,
          top: top,
          leftAndWidth: leftAndWidth,
          topAndHeight: topAndHeight,
        });
        break;
      }
    }
  }
  return selected;
};

export function randomIntFromInterval(min, max, decimals = 0) {
  const rand = Math.random() * (max - min) + min;
  const power = Math.pow(10, decimals);
  return Math.floor(rand * power) / power;
}

export const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
